<template>
  <div class="flex flex-col w-full">
    <div class="w-full" v-if="!showError">
    <card class="w-full flex flex-col mt-2 p-5">
      <div class="w-full gap-5 mb-6 flex">
        <Card class="w-3/6 py-6 px-4">
          <div class="flex justify-between">
            <Icon class-name="text-white " icon-name="people-union" size="l" />
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet"
                >Pending Requests</span
              >
              <span class="text-lg font-extrabold text-carrotOrange">{{
                stats.pendingRequests
              }}</span>
            </div>
            <div class="border bg-white w-0" />
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet"
                >Approved Requests</span
              >
              <span class="text-lg font-extrabold text-mediumSeaGreen">{{
                stats.approvedRequests
              }}</span>
            </div>
            <div class="border bg-white w-0" />
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet"
                >Disapproved Requests</span
              >
              <span class="text-lg font-extrabold text-desire">{{
                stats.disapprovedRequests
              }}</span>
            </div>
          </div>
          </Card>
        </div>
        <template v-slot:footer>
          <CardFooter
            reloadcard
            printcard
            @reload="reload"
            @actionModal="viewFilterTable($event)"
          />
        </template>
      </card>

      <card class="w-full mt-10">
        <div class="flex flex-col">
          <div
            class="w-full flex flex-col"
            v-if="requests.length !== 0 || loading"
          >
            <Table
              :headers="header"
              :items="requests"
              class="w-full mt-4 mb-4"
              aria-label="planned work"
              :has-number="true"
              :loading="loading"
              :pagination-list="metaData"
              @page="handlePage($event)"
              page-sync
              @itemsPerPage="handleItemsPerPage($event)"
            >
              <template v-slot:item="{ item }">
                <div
                  v-if="item.employee"
                  class="flex content-center"
                  style="width: 200px"
                >
                  <div>
                    <img
                      class=""
                      :src="item.data.employee.photo"
                      v-if="item.data.employee.photo"
                      alt="photo"
                      style="height: 30px; width: 30px; border-radius: 5px"
                    />
                    <div
                      style="height: 30px; width: 30px; border-radius: 5px"
                      class="text-blueCrayola border text-center font-semibold pt-2"
                      v-else
                    >
                      {{
                        $getInitials(
                          `${item.data.employee.fname} ${item.data.employee.lname}`
                        )
                      }}
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <span
                      class="font-bold cursor-pointer ml-2 capitalize"
                      style="color: #321c3b"
                      >{{ item.data.employee.fname }}
                      {{ item.data.employee.lname }}</span
                    >
                    <span
                      class="cursor-pointer ml-2 capitalize text-gray-500"
                      >{{ item.data.employee.designation?.name }}</span
                    >
                  </div>
                </div>
                <div v-if="item.category">
                  <span>
                    {{ item.data.category }}
                  </span>
                </div>
                <div v-if="item.workTitle">
                  <p>
                    {{ item.data.workTitle }}
                  </p>
                </div>
                <div v-if="item.description">
                  <p>
                    {{ item.data.description }}
                  </p>
                </div>
                <div v-if="item.startDate">
                  <span>
                    {{
                      $DATEFORMAT(
                        new Date(item.data.startDate),
                        "MMMM dd, yyyy"
                      )
                    }} </span
                  >>
                </div>
                <div v-if="item.endDate">
                  <span>
                    {{
                      $DATEFORMAT(new Date(item.data.endDate), "MMMM dd, yyyy")
                    }} </span
                  >>
                </div>
                <div v-if="item.workHours">
                  <p>
                    {{ item.data.workHours.toFixed(2) }}
                  </p>
                </div>
                <div
                  v-if="item.status"
                  style="width: 100px"
                  class="p-3"
                  :class="{
                    pending: item.data.status === 'pending',
                    approved: item.data.status === 'approved',
                    disapproved: item.data.status === 'disapproved',
                  }"
                >
                  <div class="w-auto flex flex-col">
                    <p class="text-sm font-semibold capitalize">
                      {{ item.data.status }}
                    </p>
                  </div>
                </div>
                <span v-else-if="item.requestId">
                  <Menu left top="-150" margin="24" class="my-2 p-0">
                    <template v-slot:title>
                      <icon icon-name="more_icon" size="xs" />
                    </template>
                    <div
                      class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2"
                    >
                      <div
                        class="w-full"
                        @click="
                          viewDetails(item.data.employee)
                        "
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-eye"
                            class-name="text-darkPurple"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            View Details
                          </p>
                        </div>
                      </div>
                      <div class="w-full" @click="openApproval(item.data.requestId)">
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-upgrade"
                            class-name="text-mediumSeaGreen"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            Approval Status
                          </p>
                        </div>
                      </div>
                    </div>
                  </Menu>
                </span>
              </template>
            </Table>
          </div>
          <div
            class="w-full flex flex-col mt-4 p-2 justify-center items-center"
            v-else
          >
            <p class="text-base my-2">
              There are no overtime planned work requests at the moment.
            </p>
          </div>
        </div>
      </card>

      <FilterTable
        v-if="filterTable"
        :filter-data="filterArray"
        @close="closeFilter($event)"
        @getParams="searchFilter($event)"
      />

    <FilterTable
      v-if="filterTable"
      :filter-data="filterArray"
      @close="closeFilter($event)"
      @getParams="searchFilter($event)"
    />

      <ApprovalProgress ref="approval_status" :mode="mode" />
    </div>

    <div v-else>
    <ErrorPage />
    </div>

  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import Menu from "@/components/Menu";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "Advance",
  components: {
    Table,
    Menu,
    Card,
    FilterTable,
    ErrorPage,
    Icon,
    CardFooter,
    ApprovalProgress: () => import("@/components/ApprovalStatus"),
  },
  props: {
    mode: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
      header: [
        { title: "EMPLOYEE", value: "employee" },
        { title: "Category", value: "category" },
        { title: "Work Title", value: "workTitle" },
        { title: "Description", value: "description" },
        // { title: "Start Date", value: "startDate" },
        // { title: "End Date", value: "endDate" },
        { title: "Work Hours", value: "workHours" },
        { title: "Status", value: "status" },
        { title: "", value: "requestId", image: true },
      ],
      requests: [],
      stats: {
        pendingRequests: "--",
        approvedRequests: "--",
        disapprovedRequests: "--",
      },
      query: "",
      filterArray: [
        { header: "Location(s)", value: "locationId", optionsBox: [] },
        { header: "Level(s)", value: "levelId", optionsBox: [] },
        { header: "Function(s)", value: "functionId", optionsBox: [] },
        { header: "Designation(s)", value: "designationId", optionsBox: [] },
        { header: "Status", value: "overtimeStatus", optionsBox: [] },
      ],
      filterTable: false,
      loading: true,
      showError: false
    };
  },
  methods: {
    viewDetails(data) {
      if (this.mode === "ess") {
        this.$router.push({
          name: "EssOvertimeDetails",
          params: { id: data.userId },
          query: { overtimeType: "plannedWork", type: '', name: `Planned Work` },
        });
      }
      else {
        this.$router.push({
          name: "OvertimeDetails",
          params: { id: data.userId },
          query: { overtimeType: "plannedWork", type: '', name: `Planned Work` },
        });
      }
    },
    openApproval(payload) {
      return this.$refs.approval_status.toggle(payload);
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    async plannedWorkRequests() {
      if(this.mode === 'ess') {
        this.showError = false
        await this.getRequests("&page=1&perPage=50");
        return
      }

      try {
        await this.$handlePrivilege("plannedWork", "viewRegister");
        this.showError = false;
        await this.getRequests("&page=1&perPage=50");
      } catch (error) {
        this.showError = true;
      }

    },

    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getRequests(`${pageNumber}${itemPage}`);
    },

    async getRequests(params) {
        this.loading = true;
        this.showError = false;
        if(this.mode === 'ess') {
        this.$_getManagerEmployeesPlannedWork(params)
          .then(({ data }) => {
            this.requests = data.data;
            this.metaData = data.meta;
            this.loading = false;
          })
          .catch(() => { });
        }else{
        this.$_adminPlannedWorkRequests(params)
          .then(({ data }) => {
            this.requests = data.data;
            this.metaData = data.meta;
            this.loading = false;
          })
          .catch(() => { });
        }
    },

    async getStats() {
      if (this.mode === 'admin') {
        try {
          const advanceStats = await this.$_adminPlannedWorkStats();
          this.stats = advanceStats.data;
        } catch (error) {
          // this.$router.push({ name: "ErrorRestricted" });
        }
      }
      else {
        try {
          const advanceStats = await this.$_getManagerPlannedWorksSummary();
          this.stats = advanceStats.data;
        } catch(error) {
          // this.$router.push({ name: "ErrorRestricted" });
        }
      }
    },
    reload() {
      this.getRequests("?&page=1&perPage=50");
      this.getStats();
    },

    viewFilterTable(value) {
      if (value) {
        this.filterTable = true;
      }
    },

    getAllFunctions() {
      this.$_getFunction().then((response) => {
        response.data.functions.map((functionVal) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Function(s)") {
              filter.optionsBox.push({
                id: functionVal.id,
                name: functionVal.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.map((designation) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Designation(s)") {
              filter.optionsBox.push({
                id: designation.id,
                name: designation.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLocations() {
      this.$_getHumanarLocations("").then((response) => {
        response.data.outlets.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Location(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLevels() {
      this.$_getLevels().then((response) => {
        response.data.levels.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Level(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    getStatus() {
      this.filterArray.forEach((filter) => {
        if (filter.header === "Status") {
          filter.optionsBox.push(
            {
              id: "pending",
              name: "Pending",
            },
            {
              id: "approved",
              name: "Approved",
            },
            {
              id: "disapproved",
              name: "Disapproved",
            }
          );
        }
      });
    },

    closeFilter(val) {
      if (val) {
        this.filterTable = false;
      }
    },

    searchFilter(params) {
      this.getRequests(params);
      this.filterTable = false;
    },
  },
  async mounted() {
    await this.plannedWorkRequests()
    await this.getStats();
    await this.getAllFunctions();
    await this.getAllLocations();
    await this.getAllDesignations();
    await this.getAllLevels();
    await this.getStatus();
  },
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
